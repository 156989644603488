export const API_RECIEVED_GENRES = "API_RECIEVED_GENRES",
  API_RECIEVED_MAKERS = "API_RECIEVED_MAKERS",
  API_RECIEVED_MAIN_CATEGORY = "API_RECIEVED_MAIN_CATEGORY",
  API_RECIEVED_SUB_CATEGORY = "API_RECIEVED_SUB_CATEGORY",
  API_RECIEVED_THIRD_CATEGORY = "API_RECIEVED_THIRD_CATEGORY",
  API_RECIEVED_LIST_CATEGORY = "API_RECIEVED_LIST_CATEGORY",
  API_RECIEVED_CONDITIONS = "API_RECIEVED_CONDITIONS",
  API_RECIEVED_USERS = "API_RECIEVED_USERS",
  SETTINGS_REQ_USERS = "SETTINGS_REQ_USERS",
  SETTINGS_ADD_CONDITION = "SETTINGS_ADD_CONDITION",
  SETTINGS_DELETE_CONDITION = "SETTINGS_DELETE_CONDITION",
  SETTINGS_UPDATE_CONDITION = "SETTINGS_UPDATE_CONDITION",
  SETTINGS_UPDATE_CONDITION_FAILED = "SETTINGS_UPDATE_CONDITIONS_FAILED",
  SETTINGS_ADD_CAT = {
    main_category: "SETTINGS_ADD_CAT_MAIN",
    sub_category: "SETTINGS_ADD_CAT_SUB",
    third_category: "SETTINGS_ADD_CAT_THIRD",
    list_category: "SETTINGS_ADD_CAT_LIST",
    genre: "SETTINGS_ADD_CAT_GENRE",
    maker: "SETTINGS_ADD_CAT_MAKER"
  },
  SETTINGS_UPDATE_CAT = {
    main_category: "SETTINGS_UPDATE_CAT_MAIN",
    sub_category: "SETTINGS_UPDATE_CAT_SUB",
    third_category: "SETTINGS_UPDATE_CAT_THIRD",
    list_category: "SETTINGS_UPDATE_CAT_LIST",
    genre: "SETTINGS_UPDATE_CAT_GENRE",
    maker: "SETTINGS_UPDATE_CAT_MAKER"
  },
  SETTINGS_ROW_SORT = {
    main_category: "SETTINGS_ROW_SORT_CAT_MAIN",
    sub_category: "SETTINGS_ROW_SORT_CAT_SUB",
    third_category: "SETTINGS_ROW_SORT_CAT_THIRD",
    list_category: "SETTINGS_ROW_SORT_CAT_LIST",
    genre: "SETTINGS_ROW_SORT_CAT_GENRE",
    maker: "SETTINGS_ROW_SORT_CAT_MAKER"
  },
  SETTINGS_DATA_KEYWORD_INPUT = {
    main_category: "SETTINGS_KEYWORD_INPUT_CAT_MAIN",
    sub_category: "SETTINGS_KEYWORD_INPUT_CAT_SUB",
    third_category: "SETTINGS_KEYWORD_INPUT_CAT_THIRD",
    list_category: "SETTINGS_KEYWORD_INPUT_CAT_LIST",
    genre: "SETTINGS_KEYWORD_INPUT_CAT_GENRE",
    maker: "SETTINGS_KEYWORD_INPUT_CAT_MAKER"
  },
  SETTINGS_DELETE_CAT = {
    main_category: "SETTINGS_DELETE_CAT_MAIN",
    sub_category: "SETTINGS_DELETE_CAT_SUB",
    third_category: "SETTINGS_DELETE_CAT_THIRD",
    list_category: "SETTINGS_DELETE_CAT_LIST",
    genre: "SETTINGS_DELETE_CAT_GENRE",
    maker: "SETTINGS_DELETE_CAT_MAKER"
  },
  SETTINGS_REQ_CAT_FAILED = "SETTINGS_REQ_CAT_FAILED",
  SETTINGS_KEYWORD_INPUT = "SETTINGS_KEYWORD_INPUT",
  SETTINGS_UPDATE_USER = "SETTINGS_UPDATE_USER",
  SETTINGS_ADD_USER = "SETTINGS_ADD_USER",
  SETTINGS_DELETE_USER = "SETTINGS_DELETE_USER",
  API_REQUEST_FAILED = "API_REQUEST_FAILED",
  API_REQUEST_SUCCESS = "API_REQUEST_SUCCESS",
  SETTINGS_RESET_SUCCESS = "SETTINGS_RESET_SUCCESS",
  SETTINGS_RESET_ERROR = "SETTINGS_RESET_ERROR",
  IS_LOADING = "IS_LOADING";

let defaultState = {
  genres: [],
  makers: [],
  mainCat: [],
  subCat: [],
  thirdCat: [],
  listCat: [],
  sortBy: {
    genre: '',
    maker: '',
    main_category: '',
    sub_category: '',
    third_category: '',
    list_category: '',
  },
  sortDirection: {
    genre: 'ASC',
    maker: 'ASC',
    main_category: 'ASC',
    sub_category: 'ASC',
    third_category: 'ASC',
    list_category: 'ASC',
  },
  keyword: {
    genre: '',
    maker: '',
    main_category: '',
    sub_category: '',
    third_category: '',
    list_category: '',
  },
  conditions: [],
  users: { list: [], pageData: {} }
};

const defaultCondition = {
  id: 0,
  b: 1,
  d: 1,
  f: 1,
  h: 1
};

export default function dataReducer(
  state = defaultState,
  { type, payload = {} }
) {
  switch (type) {
    case API_RECIEVED_GENRES:
      return { ...state, genres: payload.genres };
    case API_RECIEVED_MAKERS:
      return { ...state, makers: payload.makers };
    case API_RECIEVED_MAIN_CATEGORY:
      return { ...state, mainCat: payload.mainCat };
    case API_RECIEVED_SUB_CATEGORY:
      return { ...state, subCat: payload.subCat };
    case API_RECIEVED_THIRD_CATEGORY:
      return { ...state, thirdCat: payload.thirdCat };
    case API_RECIEVED_LIST_CATEGORY:
      return { ...state, listCat: payload.listCat };
    case API_RECIEVED_CONDITIONS:
      return { ...state, conditions: safeArray(payload.conditions) };
    case API_RECIEVED_USERS: {
      let { list, pageData } = payload;
      return { ...state, users: { list: safeArray(list), pageData } };
    }
    case SETTINGS_REQ_USERS: {
      let { users, ...rest } = state;
      return { ...rest, users: { ...users, pending: true } };
    }
    case API_REQUEST_FAILED:
      return {
        ...state,
        apiError: payload.error
      };
    case API_REQUEST_SUCCESS:
      return {
        ...state,
        apiSuccess: payload.success
      };
    case IS_LOADING:
      return {
        ...state,
        loading: payload.loading
      };
    case SETTINGS_ADD_CAT[payload.key]:
      return addCat(state, payload);
    case SETTINGS_UPDATE_CAT[payload.key]:
      return updateCat(state, payload);
    case SETTINGS_DELETE_CAT[payload.key]:
      return deleteCat(state, payload);
    case SETTINGS_ROW_SORT[payload.key]:
      return {
        ...state,
        sortBy: {
          ...state.sortBy,
          [payload.key]: payload.sortBy
        },
        sortDirection: {
          ...state.sortDirection,
          [payload.key]: payload.sortDirection
        }
      }
    case SETTINGS_DATA_KEYWORD_INPUT[payload.key]:
      return {
        ...state,
        keyword: {
          ...state.keyword,
          [payload.key]: payload.keyword
        }
      }
    case SETTINGS_ADD_CONDITION:
      return {
        ...state,
        conditions: [...state.conditions, defaultCondition]
      };
    case SETTINGS_UPDATE_CONDITION:
      return updateConditions(state, payload);
    case SETTINGS_UPDATE_CONDITION_FAILED:
      return { ...state, apiError: payload.error + "編集できませんでした。" };
    case SETTINGS_DELETE_CONDITION:
      return deleteConditions(state, payload);
    case SETTINGS_KEYWORD_INPUT:
      return { ...state, usersKeyword: payload.keyword };
    case SETTINGS_UPDATE_USER:
      return updateUser(state, payload);
    case SETTINGS_ADD_USER:
      return {
        ...state,
        users: {
          ...state.users,
          list: [payload.defaultUser, ...state.users.list]
        }
      };
    case SETTINGS_DELETE_USER:
      return deleteUser(state, payload);
    case SETTINGS_REQ_CAT_FAILED:
      return { ...state, apiError: payload.error };
    case SETTINGS_RESET_SUCCESS:
      return { ...state, apiSuccess: null };
    case SETTINGS_RESET_ERROR:
      return { ...state, apiError: null };
    default:
      return state;
  }
}

const catData = {
  main_category: "mainCat",
  sub_category: "subCat",
  third_category: "thirdCat",
  list_category: "listCat",
  genre: "genres",
  maker: "makers"
};
function addCat(state, { key }) {
  let data = catData[key];
  return {
    ...state,
    [data]: state[data].concat({ id: 0, name: "", itemaCount: 0 })
  };
}

function updateCat(state, { key, id, newCategory }) {
  let data = catData[key],
    res = updateWithId(state[data], id, newCategory);

  return { ...state, [data]: res };
}

function deleteCat(state, { key, id }) {
  let data = catData[key],
    idx = state[data].findIndex(entry => entry.id === id),
    res = state[data].slice(0, idx).concat(state[data].slice(idx + 1));

  return { ...state, [data]: res };
}

function updateConditions(
  { conditions: oldConditions, ...state },
  { id, newCondition }
) {
  let conditions = updateWithId(oldConditions, id, newCondition);

  return {
    ...state,
    conditions
  };
}

function deleteConditions({ conditions: oldConditions, ...state }, { idx }) {
  let conditions = oldConditions
    .slice(0, idx)
    .concat(oldConditions.slice(idx + 1));
  return {
    ...state,
    conditions
  };
}

function updateUser(
  { users: { list: oldUsers, ...users }, ...state },
  { newUser }
) {
  let newUsers = updateWithUsername();

  return { ...state, users: { ...users, list: newUsers } };

  function updateWithUsername() {
    let array = oldUsers.slice(); // copy
    for (let i = 0, old; (old = array[i]); i++) {
      if (newUser.username === old.username || old.username === "必要") {
        array[i] = { ...old, ...newUser };
        break;
      }
    }
    return array;
  }
}

function deleteUser(
  { users: { list: oldUsers, ...users }, ...state },
  { username }
) {
  let list = oldUsers.filter(entry => entry.username !== username);
  return {
    ...state,
    users: {
      ...users,
      list
    }
  };
}

function updateWithId(data, id, newObj) {
  let array = data.slice(); // copy
  for (let i = 0, old; (old = array[i]); i++) {
    if (id === old.id) {
      array[i] = { ...old, ...newObj };
      break;
    }
  }
  return array;
}

function safeArray(array) {
  return array ? array : [];
}
