import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { resetAdminError, resetAdminSuccess } from "../pages/admin/admin.actions";
import { resetDataError, resetDataSuccess, resetLogError } from "../pages/settings/settings.actions";

class Notifier extends Component {
  state = {
    errorList: [],
    successList: [],
  };

  removeError = i => () =>
    this.setState({ errorList: [...this.state.errorList.splice(i, 1)] });

  removeSuccess = i => () =>
    this.setState({ successList: [...this.state.successList.splice(i, 1)] });

  componentDidUpdate() {
    const  {
      adminError,
      dataError,
      logError,
      adminSuccess,
      dataSuccess,
      resetAdminError,
      resetAdminSuccess,
      resetDataError,
      resetDataSuccess,
      resetLogError,
    } = this.props;

    const errorList = [];
    const successList = [];

    if (adminError) {
      const errorList = [...this.state.errorList, adminError];
      resetAdminError();
      this.setState(state => ({
        ...state,
        errorList,
      }));
    }

    if (adminSuccess) {
      const successList = [...this.state.successList, adminSuccess];
      resetAdminSuccess();
      this.setState(state => ({
        ...state,
        successList,
      }));
    }

    if (dataError) {
      const errorList = [...this.state.errorList, dataError];
      resetDataError();
      this.setState(state => ({
        ...state,
        errorList,
      }));
    }

    if (dataSuccess) {
      const successList = [...this.state.successList, dataSuccess];
      resetDataSuccess();
      this.setState(state => ({
        ...state,
        successList,
      }));
    }

    if (logError) {
      const errorList = [...this.state.errorList, logError];
      resetLogError();
      this.setState(state => ({
        ...state,
        errorList,
      }));
    }
  }

  render() {
    return (
      <div id="notifier">
        {this.state.errorList.map((entry, i) => (
          <ErrorMessage
            key={"error" + i}
            message={typeof entry === "string" ? entry : entry.message}
            remove={this.removeError(i)}
          />
        ))}
        {this.state.successList.map((entry, i) => (
          <SuccessMessage
            key={"success" + i}
            message={typeof entry === "string" ? entry : entry.message}
            remove={this.removeSuccess(i)}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({
  admin: { apiError: adminError, apiSuccess: adminSuccess },
  data: { apiError: dataError, apiSuccess: dataSuccess },
  histories: { error: logError }
}) => ({ adminError, dataError, logError, adminSuccess, dataSuccess });

const mapDispatchToProps = {
  resetAdminError,
  resetAdminSuccess,
  resetDataError,
  resetDataSuccess,
  resetLogError,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);

class ErrorMessage extends Component {
  state = {
    entering: true
  };

  componentDidMount() {
    setTimeout(() => this.setState({ entering: false }), 500);
    setTimeout(() => this.dismiss(), 15000);
  }

  dismiss = e => {
    this.setState({ entering: true });
    setTimeout(() => this.props.remove(), 1000);
  };

  render() {
    return (
      <div
        onClick={this.dismiss}
        className={cx("error-message", { entering: this.state.entering })}
      >
        <h4>Error</h4>
        <p>{this.props.message}</p>
      </div>
    );
  }
}

class SuccessMessage extends Component {
  state = {
    entering: true
  };

  componentDidMount() {
    setTimeout(() => this.setState({ entering: false }), 500);
    setTimeout(() => this.dismiss(), 15000);
  }

  dismiss = e => {
    this.setState({ entering: true });
    setTimeout(() => this.props.remove(), 1000);
  };

  render() {
    return (
      <div
        onClick={this.dismiss}
        className={cx("success-message", { entering: this.state.entering })}
      >
        <h4>Success</h4>
        <p>{this.props.message}</p>
      </div>
    );
  }
}
