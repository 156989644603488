import {
  SETTINGS_UPLOAD_PENDING,
  SETTINGS_UPLOAD_SUCCESS,
  SETTINGS_UPLOAD_FAILURE,
} from "../../reducers/admin.reducer.js";
import {
  API_RECIEVED_CONDITIONS,
  API_REQUEST_FAILED,
  SETTINGS_ADD_CONDITION,
  SETTINGS_UPDATE_CONDITION,
  SETTINGS_UPDATE_CONDITION_FAILED,
  SETTINGS_DELETE_CONDITION,
  SETTINGS_ADD_CAT,
  SETTINGS_UPDATE_CAT,
  SETTINGS_DELETE_CAT,
  SETTINGS_REQ_CAT_FAILED,
  SETTINGS_KEYWORD_INPUT,
  SETTINGS_ADD_USER,
  SETTINGS_UPDATE_USER,
  SETTINGS_DELETE_USER,
  SETTINGS_ROW_SORT,
  SETTINGS_DATA_KEYWORD_INPUT,
  SETTINGS_RESET_SUCCESS,
  SETTINGS_RESET_ERROR,
} from "../../reducers/data.reducer.js";
import {
  SETTINGS_REQ_LOG,
  SETTINGS_RECIEVED_LOG,
  SETTINGS_LOG_FAILED,
  SETTINGS_LOG_RESET_SUCCESS,
  SETTINGS_LOG_RESET_ERROR,
} from "../../reducers/histories.reducer.js";
import {
  addCategory,
  updateCategory,
  deleteCategory,
  reqConditions,
  addCondition,
  updateCondition,
  reqDeleteCondition,
  updateUser,
  deleteUser,
  addUser,
  reqExportCSV,
  importCSV,
  reqHistories
} from "../../auth/api.js";

export const reqConditionsThunk = () => async dispatch => {
  try {
    var { results } = await reqConditions();
  } catch (error) {
    return dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error
      }
    });
  }

  return dispatch({
    type: API_RECIEVED_CONDITIONS,
    payload: {
      conditions: results.list
    }
  });
};

export const addNewCondition = () => ({
  type: SETTINGS_ADD_CONDITION
});

export const deleteCondition = idx => ({
  type: SETTINGS_DELETE_CONDITION,
  payload: {
    idx
  }
});

export const deleteConditionThunk = id => async dispatch => {
  try {
    await reqDeleteCondition({ id });
    return;
  } catch (error) {
    dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error
      }
    });
    return;
  }
};

export const addConditionsThunk = formData => async dispatch => {
  try {
    var { results } = await addCondition(formData);
  } catch (error) {
    return dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error
      }
    });
  }
  // API確認出来たなら、IDアプデートする
  return dispatch({
    type: SETTINGS_UPDATE_CONDITION,
    payload: {
      id: 0, // 追加したアイテムはID=0
      newCondition: results.result
    }
  });
};

export const editConditionsThunk = (id, formData) => async dispatch => {
  dispatch({
    type: SETTINGS_UPDATE_CONDITION,
    payload: {
      id,
      newCondition: formData
    }
  });
  try {
    await updateCondition(id, formData);
    return;
  } catch (error) {
    dispatch({
      type: SETTINGS_UPDATE_CONDITION_FAILED,
      payload: {
        error
      }
    });
    return;
  }
};

export const addNewCategory = key => ({
  type: SETTINGS_ADD_CAT[key],
  payload: { key }
});

export const delCategory = (key, id) => ({
  type: SETTINGS_DELETE_CAT[key],
  payload: {
    key,
    id
  }
});

export const deleteCategoryThunk = (key, id) => async dispatch => {
  try {
    await deleteCategory[key]({ id });
    return;
  } catch (error) {
    dispatch({
      type: SETTINGS_REQ_CAT_FAILED,
      payload: {
        error
      }
    });
    return;
  }
};

export const addCategoryThunk = (key, formData) => async dispatch => {
  try {
    var { results } = await addCategory[key](formData);
  } catch (error) {
    return dispatch({
      type: SETTINGS_REQ_CAT_FAILED,
      payload: {
        error: error.message
      }
    });
  }
  // API確認出来たなら、IDアプデートする
  return dispatch({
    type: SETTINGS_UPDATE_CAT[key],
    payload: {
      key,
      id: 0, // 追加したアイテムはID=0
      newCategory: results.result
    }
  });
};

export const sortRowsBy = ({ key, sortBy, sortDirection }) => ({
  type: SETTINGS_ROW_SORT[key],
  payload: {
    key,
    sortBy,
    sortDirection
  }
});

export const keywordSearchThunk = ({ key, keyword } = {}) => ({
  type: SETTINGS_DATA_KEYWORD_INPUT[key],
  payload: { key, keyword }
});

export const editItem = ({ key, id }, newCategory) => ({
  type: SETTINGS_UPDATE_CAT[key],
  payload: {
    key,
    id,
    newCategory
  }
});

export const editCategoryThunk = ({ key, id }, formData) => async dispatch => {
  try {
    await updateCategory[key](id, formData);
    return;
  } catch (error) {
    dispatch({
      type: SETTINGS_REQ_CAT_FAILED,
      payload: {
        error: error.message
      }
    });
    return;
  }
};

/** Users page */
export const updateUserThunk = formData => async (dispatch, getState) => {
  let item = getState().data.users.list.find(
    entry => entry.username === formData.username || entry.new
  );

  dispatch({ type: SETTINGS_UPDATE_USER, payload: { newUser: formData } });

  let updateAction;
  if (item.new) {
    updateAction = addUser;
  } else updateAction = updateUser;

  try {
    let data = { ...item, ...formData };
    if (!testValid(data)) {
      return;
    }

    let { results } = await updateAction(data);
    if (data.new) delete data.new;

    if (results.failure) {
      throw new Error("保存はまだ出来ませんでした。理由：" + results.message);
    }
    return;
  } catch (error) {
    dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error: error.message
      }
    });
    return;
  }
};

function testValid(entry) {
  return ["username", "email"].reduce(
    (acc, key) => entry[key] && entry[key] !== "必要" && acc,
    true
  );
}

export const addNewUser = () => ({
  type: SETTINGS_ADD_USER,
  payload: {
    defaultUser: {
      username: "必要",
      email: "必要",
      new: true
    }
  }
});

export const deleteUsersThunk = selectedSet => async (dispatch, getState) => {
  const backup = getState().data.users.list;
  for (let username of selectedSet) {
    dispatch({ type: SETTINGS_DELETE_USER, payload: { username } });

    try {
      let { results } = await deleteUser({ username });
      if (results.status === "failed")
        throw new Error("ユーザー削除出来ませんでした。");
    } catch (error) {
      dispatch({
        type: API_REQUEST_FAILED,
        payload: {
          error: error.message
        }
      });
      dispatch({
        type: SETTINGS_ADD_USER,
        payload: {
          defaultUser: backup.find(entry => entry.username === username) || {}
        }
      });
    }
  }
};

export const keywordSearch = ({ keyword } = {}) => ({
  type: SETTINGS_KEYWORD_INPUT,
  payload: { keyword }
});

export async function downloadCSV() {
  try {
    let Response = await reqExportCSV();
    let blob = await Response.blob();

    return window.URL.createObjectURL(blob);
  } catch (error) {
    console.log(error);
  }
}

export const sendCSV = formData => async dispatch => {
  dispatch({
    type: SETTINGS_UPLOAD_PENDING
  });
  try {
    const result = await importCSV(formData);
    return dispatch({
      type: SETTINGS_UPLOAD_SUCCESS,
      payload: { success: "アップロードが完了しました" }
    });
  } catch (error) {
    console.log(error)
    return dispatch({
      type: SETTINGS_UPLOAD_FAILURE,
      payload: { error: error.message }
    });
  }
};

export const reqLogThunk = params => async dispatch => {
  dispatch({
    type: SETTINGS_REQ_LOG
  });
  try {
    var { results } = await reqHistories(params);
  } catch (error) {
    return dispatch({
      type: SETTINGS_LOG_FAILED,
      payload: {
        error
      }
    });
  }

  let { list, pageData } = results;
  return dispatch({
    type: SETTINGS_RECIEVED_LOG,
    payload: {
      list,
      pageData
    }
  });
};

export const resetDataSuccess = () => ({
  type: SETTINGS_RESET_SUCCESS
})

export const resetDataError = () => ({
  type: SETTINGS_RESET_ERROR
})

export const resetLogSuccess = () => ({
  type: SETTINGS_LOG_RESET_SUCCESS
})

export const resetLogError = () => ({
  type: SETTINGS_LOG_RESET_ERROR
})
