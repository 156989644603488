export const API_REQUEST_HISTORIES = "API_REQUEST_HISTORIES",
  API_RECIEVED_HISTORIES = "API_RECIEVED_HISTORIES",
  SETTINGS_REQ_LOG = "SETTINGS_REQ_LOG",
  SETTINGS_LOG_FAILED = "API_LOG_FAILED",
  SETTINGS_RECIEVED_LOG = "API_RECIEVED_LOG",
  SETTINGS_LOG_RESET_SUCCESS = "SETTINGS_LOG_RESET_SUCCESS",
  SETTINGS_LOG_RESET_ERROR = "SETTINGS_LOG_RESET_ERROR";

const defaultState = {
  list: []
};

export default function historyReducer(
  state = defaultState,
  { type, payload }
) {
  switch (type) {
    case API_RECIEVED_HISTORIES:
      return recievedHistories(state, payload);
    case SETTINGS_REQ_LOG:
      return { ...state, pending: true };
    case SETTINGS_RECIEVED_LOG: {
      let { pending, ...rest } = state,
        { list = [], pageData = {} } = payload;
      return { ...rest, list, pageData };
    }
    case SETTINGS_LOG_FAILED:
      return { ...state, pending: false, error: payload.error };
    case SETTINGS_LOG_RESET_SUCCESS:
      return { ...state, success: null };
    case SETTINGS_LOG_RESET_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
}

function recievedHistories(state, { histories, pageData }) {
  let newHistories = histories.reduce((acc, newItem) => {
    return !acc.find(currItem => currItem.id === newItem.id)
      ? acc.concat(newItem)
      : acc;
  }, state.list);
  return {
    ...state,
    list: newHistories,
    pageData
  };
}
