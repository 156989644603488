import { yenFormatInstance } from "../utils/formatters";

export const LIMIT_UPDATE_PRICE = 300000;

/**
 * バリデーションロジック
 * @param {Object} data - チェック対象データ
 * @returns {Object} - { isValid: boolean, errors: string[] }
 */
export const validatePriceRanks = (data) => {
    const rankOrder = ['s', 'a', 'b', 'c', 'd']; // ランクの順序
    const errors = [];
    const limitPrice = LIMIT_UPDATE_PRICE; // 上限価格
    let previousRankPrice = null; // 通常ランクの前価格を記録
    let previousNetworkLimitPrice = null; // ネットワーク制限ランクの前価格を記録

    for (const rank of rankOrder) {
        const rankKey = `price_rank_${rank}`;
        const networkLimitKey = `network_limit_price_rank_${rank}`;

        const rankPrice = parseInt(data[rankKey] || 0, 10);
        const networkLimitPrice = parseInt(data[networkLimitKey] || 0, 10);

        // 上限チェック
        if (rankPrice >= limitPrice) {
            errors.push(`${rankKey} の価格が上限値の ${limitPrice.toLocaleString()} 円以上です。`);
        }
        if (networkLimitPrice >= limitPrice) {
            errors.push(`${networkLimitKey} の価格が上限値の ${limitPrice.toLocaleString()} 円以上です。`);
        }

        // price_rank > network_limit_price_rank のチェック
        if (rankPrice > 0 && networkLimitPrice > 0 && rankPrice < networkLimitPrice) {
            errors.push(`${rankKey} の価格は ${networkLimitKey} の価格以上である必要があります。`);
        }

        // 通常ランクの順序チェック
        if (previousRankPrice !== null && rankPrice > 0 && rankPrice > previousRankPrice) {
            errors.push(`${rankKey} の価格は上位ランクの価格以下である必要があります。`);
        }

        // ネットワーク制限ランクの順序チェック
        if (previousNetworkLimitPrice !== null && networkLimitPrice > 0 && networkLimitPrice > previousNetworkLimitPrice) {
            errors.push(`${networkLimitKey} の価格は上位ランクの価格以下である必要があります。`);
        }

        // 前ランクの価格を更新
        if (rankPrice > 0) {
            previousRankPrice = rankPrice;
        }

        // 前ランクのネットワーク制限価格を更新
        if (networkLimitPrice > 0) {
            previousNetworkLimitPrice = networkLimitPrice;
        }
    }

    return {
        isValid: errors.length === 0,
        errors
    };
};
