import { configureThunks } from "../../utils/apiUtils.js";
import { validatePriceRanks } from "../../utils/priceCheck.js";
import {
  reqKeyword,
  deleteItem,
  addItem,
  updateItem,
  reqHistories,
  reqId
} from "../../auth/api.js";
import { API_REQUEST_FAILED, IS_LOADING, API_REQUEST_SUCCESS } from "../../reducers/data.reducer.js";
import { API_RECIEVED_HISTORIES } from "../../reducers/histories.reducer.js";
import {
  ADM_SELECTED_MAIN_TAG,
  ADM_SELECTED_TAG,
  ADM_RECIEVED_ITEMS,
  ADM_REQUEST_PENDING,
  ADM_SELECT_ITEM,
  ADM_DESELECT,
  ADM_KEYWORD_INPUT,
  ADM_DEL_SELECTED_ROWS,
  ADM_FAILED_DELETION,
  ADM_ADDED_ITEM,
  ADM_UPDATE_ITEMS,
  ADM_UPDATE_ITEM,
  ADM_ROW_SORT,
  ADM_TOGGLE_PANEL,
  ADM_SELECT_ITEMS,
  ADM_RESET_ERROR,
  ADM_RESET_SUCCESS,
} from "../../reducers/admin.reducer.js";
import networkLimited  from "../../utils/networkLmitedDefs.js";

let buttonActionId = null;

const reqThunk = configureThunks({ errorCode: API_REQUEST_FAILED });

export const selectMainTag = tag => ({
  type: ADM_SELECTED_MAIN_TAG,
  payload: {
    tag
  }
});

export const selectTag = tag => ({
  type: ADM_SELECTED_TAG,
  payload: {
    tag
  }
});

export const selectItem = itemId => async (
  dispatch,
  getState
) => {
  dispatch({
    type: ADM_SELECT_ITEM,
    payload: {
      itemId
    }
  });
  let requestParams = {};
  try {
    requestParams.page = 1;
    requestParams.item = itemId;
    var { results } = await reqHistories(requestParams);
    let { pageData = {}, list } = results;
    dispatch({
      type: API_RECIEVED_HISTORIES,
      payload: {
        histories: list,
        pageData
      }
    })
    for (let i = 2; i <= pageData.pageCount; i++) {
      requestParams.page = i;
      reqHistories(requestParams).then(({ results: { list, pageData } }) =>
        dispatch({
          type: API_RECIEVED_HISTORIES,
          payload: {
            histories: list,
            pageData
          }
        })
      );
    }
  } catch (error) {
    return dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error: error.message
      }
    });
  }
};

export const deselectItems = () => ({
  type: ADM_DESELECT
});

export const selectItems = (items) => ({
  type: ADM_SELECT_ITEMS,
  payload: {
    items
  }
});

export const sortRowsBy = (sortBy, sortDirection) => ({
  type: ADM_ROW_SORT,
  payload: {
    sortBy,
    sortDirection
  }
});

export const keywordSearchThunk = ({ keyword } = {}) => ({
  type: ADM_KEYWORD_INPUT,
  payload: { keyword }
});

export const reqItemsThunk = (requestParams = {}) => async (
  dispatch,
  getState
) => {
  let { selectedMainTag } = getState().admin;
  let { selectedTag } = getState().admin;

  dispatch({ type: ADM_REQUEST_PENDING });
  // サーバーで新しいデータあったら
  if (selectedMainTag) requestParams.main_category = selectedMainTag;
  if (selectedTag) requestParams.list_category = selectedTag;

  try {
    var { results } = await reqKeyword(requestParams);
    let { pageData = {}, list } = results;
    dispatch({
      type: ADM_RECIEVED_ITEMS,
      payload: { items: list, pageData }
    });

    for (let i = 2; i <= pageData.pageCount; i++) {
      requestParams.page = i;
      reqKeyword(requestParams).then(({ results: { list, pageData } }) =>
        dispatch({
          type: ADM_RECIEVED_ITEMS,
          payload: {
            items: list,
            pageData
          }
        })
      );
    }
  } catch (error) {
    return dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error: error.message
      }
    });
  }
};

export const reqByIdThunk = reqThunk({
  request: reqId,
  pendingFlag: ADM_REQUEST_PENDING,
  resAction: results => ({
    type: ADM_RECIEVED_ITEMS,
    payload: { items: [results] }
  })
});

export const setButtonActionId = (id) => buttonActionId = id;

export const updateSelectedItemsThunk = (formData, formApi) => async (
    dispatch,
    getState
) => {
  const { selectedItems, items } = getState().admin;
  console.log('selectedItems', selectedItems);
  // 選択されたアイテムのインデックスを取得
  const indexes = selectedItems.map(id =>
      items.findIndex(entry => entry.id === id)
  );
  console.log('indexes', indexes);
  /** @type {Array.<[number, Object]>} */
  const itemsList = indexes.map(index => [
    index,
    {
      id: items[index].id,
      newData: applyPriceChanges(items[index], formData),
      existingData: items[index]
    }
  ]);
  console.log('itemsList', itemsList);
  // バリデーション処理
  const errorFormData = itemsList
      .map(([index, { newData, existingData }]) => {
        console.log("newData", newData);
        console.log("existingData", existingData);
        const mergedData = { ...existingData, ...newData };
        console.log('mergedData', mergedData);
        const validationResult = validatePriceRanks(mergedData);
        console.log('validationResult', validationResult);
        // エラーが存在する場合のみエントリを追加
        if (!validationResult.isValid) {
          return [index, validationResult];
        }
        return null;
      })
      .filter(Boolean);
  console.log('errorFormData', errorFormData);
  if (errorFormData.length > 0) {
    const errorMessages = errorFormData
        .flatMap(([index, { errors }]) =>
            errors.map((error) => `Row ${index}: ${error}`)
        );
    return dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error: `バリデーションエラー:\n${errorMessages}`
      }
    });
  }

  try {
    dispatch({ type: IS_LOADING, payload: { loading: true } });

    const requests = itemsList.map(async ([index, { id, newData }], listIndex) => {
      await updateItem(id, newData);
      itemsList[listIndex] = [index, { id, newData }];

      return [index, { id, newData }];
    });

    dispatch({ type: ADM_UPDATE_ITEMS, payload: { itemsList } });
    dispatch({ type: ADM_DESELECT, selectedItems: [] });

    await Promise.all(requests);

    //商品変わると、新しい履歴がある
    const histories = itemsList.map(([, { id }]) =>
      reqHistories({ item: id }).then(({ results: { list, pageData } }) =>
        dispatch({
          type: API_RECIEVED_HISTORIES,
          payload: {
            histories: list,
            pageData
          }
        })
      )
    );

    dispatch({
      type: API_REQUEST_SUCCESS,
      payload: {
        success: "一括更新が完了しました"
      }
    });

    return histories;
  } catch (error) {
    return dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error: error.message
      }
    });
  } finally {
    dispatch({ type: IS_LOADING, payload: { loading: false } });
  }
};

function calcPrice({ item, prefix, target, formData, key }) {
  const newData = {};
  const currentPrice = item[target] ? Number(item[target]) : 0;

  if (formData[key] === '') {
    return newData;
  }

  switch (prefix) {
    case "p$": // パーセンテージ
      newData[target] = Math.round(currentPrice * (formData[key] / 100));
      break;
    case "f$": // fixed
      newData[target] = Math.round(currentPrice + formData[key]);
      break;
    default:
      newData[target] = Math.round(formData[key]);
      break;
  }

  return newData;
}

/** @returns {Object} */
function applyPriceChanges(item, formData) {
  let newData = {};

  for (let key in formData) {
    let prefix = key.substr(0, 2),
        target = key.replace(/p\$|f\$/g, '');

    if (buttonActionId === networkLimited.NON_LIMITED || buttonActionId === networkLimited.BOTH) {
      newData = { ...newData, ...calcPrice({ item, prefix, target, formData, key }) };
    }

    if (item.network_limit === 2 && (buttonActionId === networkLimited.LIMITED || buttonActionId === networkLimited.BOTH)) {
      target = `network_limit_${target}`;
      newData = { ...newData, ...calcPrice({ item, prefix, target, formData, key }) };
    }
  }

  return newData;
}

export const deleteRowsThunk = () => async (dispatch, getState) => {
  let { selectedItems, items } = getState().admin;

  dispatch({ type: ADM_DEL_SELECTED_ROWS });

  try {
    const results = await Promise.all(deleteRows(selectedItems));
    results.forEach(({ results }) => {
      if (results.status !== "success") throw new Error(results.status);
    });
  } catch (error) {
    return dispatch({
      type: ADM_FAILED_DELETION,
      payload: {
        selectedItems,
        items,
        error: error.message
      }
    });
  }
};

function deleteRows(selectedItems) {
  return selectedItems.map(entry => deleteItem({ id: entry }));
}

export const addNewItemThunk = () => async (dispatch, getState) => {
  let { selectedTag } = getState().admin;

  try {
    const { results } = await addItem({ list_category_id: selectedTag });
    if (results.status === "failure") throw new Error(results.message);

    let { result } = results;
    dispatch({
      type: ADM_ADDED_ITEM,
      payload: { item: result }
    });
    return result;
  } catch (error) {
    dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error: error.message
      }
    });
    return false;
  }
};

export const updateCellThunk = (formData, prevData) => async dispatch => {
  let { id, ...data } = formData;
  dispatch({ type: ADM_UPDATE_ITEM, payload: { id, formData } });

  try {
    const result = await updateItem(id, data);
    if (result.status === "failure") {
      throw new Error(result.message);
    }

    //商品変わると、新しい履歴ある
    reqHistories({ item: id }).then(({ results: { list, pageData } }) =>
      dispatch({
        type: API_RECIEVED_HISTORIES,
        payload: {
          histories: list,
          pageData
        }
      })
    );
    return true;
  } catch (error) {
    dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error: error.message
      }
    });
    // 失敗したら、前のデータに戻す
    prevData && dispatch({ type: ADM_UPDATE_ITEM, payload: { id, formData: prevData } });
    return false;
  }
};

export const togglePanel = () => ({
  type: ADM_TOGGLE_PANEL
});

export const resetAdminSuccess = () => ({
  type: ADM_RESET_SUCCESS
})

export const resetAdminError = () => ({
  type: ADM_RESET_ERROR
})
